import "./App.css";
import bgimage from "./assets/bg.png";

import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { parseUnits, BrowserProvider, Contract, formatUnits } from 'ethers'

// 1. Get projectId
const projectId = '425e5c3e41bb4f4ff4fa197aa4d08576'

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://cloudflare-eth.com'
}

// 3. Create a metadata object
const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://mywebsite.com', // origin must match your domain & subdomain
  icons: ['https://avatars.mywebsite.com/']
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: '...', // used for the Coinbase SDK
  defaultChainId: 1 // used for the Coinbase SDK
})

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})

const tokenca = '0xF453579d18a6F8CA07db9250e0E0100EB8CCb206'
const stake = '0xEC8e8Fa10DA9a48881A9E7A4848E2B5f56b00F3c';

const tokenabi = [
  'function name() view returns (string)',
  'function symbol() view returns (string)',
  'function balanceOf(address) view returns (uint)',
  'function transfer(address to, uint amount)',
  'event Transfer(address indexed from, address indexed to, uint amount)',
  'function approve(address spender,uint256 amount)'
]
const stakeabi = [
  'function totalstaked() view returns (uint256)',
  'function depositedTokens(address) view returns (uint256)',
  'function getPendingDivs(address _holder) view returns (uint256 _pendingDivs)',
  'function deposit(uint256 amountToStake)',
  'function totalClaimedRewards() view returns (uint256)',
  'function withdraw(uint256 amountToWithdraw)',
  'function claimDivs()',
  'function getNumberOfHolders() view returns (uint256)'
];


function App() {
  const { address, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()
  let balance, fb, dep;

  async function start(){
  if (isConnected){
    const ethersProvider = new BrowserProvider(walletProvider)
    const signer = await ethersProvider.getSigner()
    // The Contract object
    const TokenContract = new Contract(tokenca, tokenabi, signer)
    const StakeContract = new Contract(stake, stakeabi, signer)

    balance = await TokenContract.balanceOf(address)
    fb = formatUnits(balance, 18);
    document.getElementById("blc").innerHTML = fb;

    const pd = await StakeContract.getPendingDivs(address)
    let pe = formatUnits(pd, 15);
    document.getElementById("cipher-claim").innerHTML = Math.round(pe)/1000;

    document.getElementById("number").innerHTML = await StakeContract.getNumberOfHolders()

    dep = await StakeContract.depositedTokens(address)
    let d = formatUnits(dep, 18);
    document.getElementById("dep").innerHTML = d;
    document.getElementById("avl").innerHTML = d;

    const ttc = await StakeContract.totalstaked()
    let tc = formatUnits(ttc, 18);
    document.getElementById("tvl").innerHTML = "$"+(tc*0.002).toLocaleString("en-US");

    document.getElementById('stake').onclick = async function() {
      let x = document.getElementById('amt').value;
      let r = parseUnits(x, 18)
      let tx = await StakeContract.deposit(r);
      await tx.wait()
    };

    document.getElementById('unstake').onclick = async function() {
      let x = document.getElementById('uamt').value;
      let r = parseUnits(x, 18)
      let tx = await StakeContract.withdraw(r);
      await tx.wait()
    };

    document.getElementById('approve').onclick = async function() {
      let x = document.getElementById('amt').value;
      let r = parseUnits(x, 18)
      let tx = await TokenContract.approve(stake, r);
      await tx.wait()
    };

    document.getElementById('claim').onclick = async function() {
      let tx = await StakeContract.claimDivs();
      await tx.wait()
    };
  }}
start();
  return (
    <div className="App min-h-screen w-full bg-black flex items-center relative justify-center">
      <img
        src={bgimage}
        className="w-full h-full object-cover absolute top-0"
        alt=""
      />
      <div className="container w-full h-full flex flex-col items-center justify-center relative">
        <h1 className="md:text-6xl text-3xl text-white font-clash font-bold uppercase mt-10">
          Cipher Stake
        </h1>
        <p className="md:text-3xl text-xl text-white/80 md:w-1/3 w-full text-center mb-10 font-clash">
          Stake <span className="text-white font-bold">$CIPHER</span> Token
        </p>
        {/* connect wallet goes here */}
        <w3m-button />
        <div className="card  rounded-2xl relative px-4 flex md:flex-row flex-col items-center justify-center my-4 w-full">
          <div className="stake_card bg-[#101010]  md:w-96 w-full px-4 py-4 rounded-2xl border-[0.5px] gap-2  flex flex-col border-white/50 ">
            <h1 className="text-white text-4xl font-clash font-semibold text-center flex flex-col items-start justify-start gap-1">
              <span className="text-lg text-white/40">Your Staked Balance: ($CIPHER)</span><b id="dep">0.0</b>
            </h1>
            

          </div>
          
        </div>
        <div className="card  rounded-2xl relative px-4 flex md:flex-row flex-col items-center justify-center my-4 w-full">
          <div className="stake_card bg-[#101010]  md:w-96 w-full px-4 py-4 rounded-2xl border-[0.5px] gap-2  flex flex-col border-white/50 ">
            <h1 className="text-white text-4xl font-clash font-semibold text-center flex flex-col items-start justify-start gap-1">
              <span className="text-lg text-white/40">Your Earning: ($CIPHER)</span><b id="cipher-claim">0.0</b>
            </h1>
            <button id="claim" className="py-2 bg-white text-black font-clash font-medium text-lg rounded-lg mb-4">
              Claim
            </button>
          </div>
          
        </div>
        <div className="card  rounded-2xl relative px-4 flex md:flex-row flex-col items-center justify-center my-4 w-full">
          <div className="stake_card bg-[#101010]  md:w-96 w-full px-4 py-4 rounded-2xl border-[0.5px] gap-2  flex flex-col border-white/50 ">
            <h1 className="text-white text-4xl font-clash font-semibold text-center flex items-start justify-start gap-1">
              <span className="text-lg text-white/80">APY Rate: <b>90% APY</b></span>
            </h1>
            <div className="flex flex-col items-start justify-start">
              <h1 className="text-white text-2xl  font-clash font-semibold text-center flex items-start justify-center gap-1">
                <span className="text-sm text-white/80">Locked Period: <b>7 Days</b></span>
              </h1>{" "}
              <h1 className="text-white text-2xl  font-clash font-semibold text-center flex items-start justify-center gap-1">
                <span className="text-sm text-white/80">Status: <b>Locked</b></span>
              </h1>
            </div>
            <p className="text-white/50 ">Available For Staking</p>
            <h1 className="text-lg text-white/80 font-clash"><span id="blc" className="text-white font-bold">0.0 $CIPHER</span>
            </h1>
            <input
              className="text-lg text-white/80 font-clash bg-black border-[1px] border-white p-2  rounded-lg"
              prefix="$CIPHER"
              type="number"
              id="amt"
            />
            <button id="approve" className="py-2 bg-white text-black font-clash font-medium text-lg rounded-lg mb-4">
              Approve
            </button>
            <button id="stake" className="py-2 bg-white text-black font-clash font-medium text-lg rounded-lg mb-4">
              Stake
            </button>

            <p className="text-white/50 ">Available for withdraw</p>
            <h1 className="text-lg text-white/80 font-clash"><span id="avl" className="text-white font-bold">0.0 $CIPHER</span>
            </h1>
            <input
              className="text-lg text-white/80 font-clash bg-black border-[1px] border-white p-2  rounded-lg"
              prefix="$CIPHER"
              type="number"
              id="uamt"
            />
            <button
              className="py-2 bg-white text-black font-clash font-medium text-lg rounded-lg mb-4" id="unstake"
            >
              Withdraw
            </button>
          </div>
          
        </div>
        <div className="card  rounded-2xl relative px-4 flex md:flex-row flex-col items-center justify-center my-4 w-full">
          <div className="stake_card bg-[#101010]  md:w-96 w-full px-4 py-4 rounded-2xl border-[0.5px] gap-2  flex flex-col border-white/50 ">
            <h1 className="text-white text-4xl font-clash font-semibold text-center flex flex-col items-start justify-start gap-1">
              <span className="text-lg text-white/40">Total Value Unlocked</span><b id="tvl">....</b>
            </h1>
            

          </div>
          
        </div>
        <div className="card  rounded-2xl relative px-4 flex md:flex-row flex-col items-center justify-center my-4 w-full">
          <div className="stake_card bg-[#101010]  md:w-96 w-full px-4 py-4 rounded-2xl border-[0.5px] gap-2  flex flex-col border-white/50 ">
            <h1 className="text-white text-4xl font-clash font-semibold text-center flex flex-col items-start justify-start gap-1">
              <span className="text-lg text-white/40">Number of stakers</span><b id="number">....</b>
            </h1>
            

          </div>
          
        </div>
      </div>
    </div>
  );
}

export default App;
